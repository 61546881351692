import React from 'react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import {graphql} from "gatsby"
import NavigationList from '../components/navigation-list'
import _ from "lodash";


class ProjectIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const projects = get(this, 'props.data.allContentfulProjekt.edges')
    const sorted = _.orderBy(projects, 'node.date', 'desc')
    return (
      <Layout location={this.props.location} showNavigation={true} path={[{text: 'Projekte'}]}>
        <Helmet title={siteTitle}>
          <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
        </Helmet>
        <NavigationList
          links={sorted.map((project => ({path: "/projekt/" + project.node.slug,
            text: project.node.titleShort})))}>
        </NavigationList>
      </Layout>
    )
  }
}

export default ProjectIndex

export const pageQuery = graphql`
  query ProjektIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulProjekt {
      edges {
        node {
          title
          titleShort
          slug
          date
        }
      }
    }
  }
`

